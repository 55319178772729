import React, { Component } from 'react';
import './App.css';
import Myrouter from "./logic/myrouter";




class App extends Component {
    render() {
        return (
          <Myrouter/>
        );
    }
}

export default App;
